<template>
  <div class="page-accounts">
    <div class="columns is-gapless is-vh">
      <div class="column is-2 has-background-dark">
        <Sidebar/>
      </div>

      <div class="column">
        <div class="section">
          <h1 class="title">Accounts Overview</h1>

          <hr>

          <AccountsOverview/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import AccountsOverview from '@/components/AccountsOverview.vue'

export default {
  name: 'Accounts',
  components: {
    Sidebar,
    AccountsOverview,
  }
}
</script>
