<template>
  <div class="accounts-overview">
    <nav class="level">
      <div class="level-left">
        <p class="level-item">
          <b-button
            @click="reload"
            type="is-info"
            icon-left="reload">
            Reload
          </b-button>
        </p>
      </div>
    </nav>

    <b-table
      narrowed
      hoverable
      striped
      paginated
      backend-pagination
      @page-change="pageChange"
      default-sort="created"
      default-sort-direction="desc"
      :per-page="limit"
      :total="total"
      :loading="loading"
      :data="data">

      <template slot-scope="props">
        <b-table-column field="id" label="No." sortable>
          {{ props.index + 1 + parseInt(lastRequest.skip ? lastRequest.skip : 0) }}
        </b-table-column>

        <b-table-column field="name" label="Name" sortable>
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="email" label="Email" sortable>
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="status" label="Status" sortable>
          <span :class="statusClass(props.row.status)">{{ statusName(props.row.status) }}</span>
        </b-table-column>

        <b-table-column field="updated" label="Updated" sortable>
          <span class="tag is-info">
            {{ props.row.updated }}
          </span>
        </b-table-column>

        <b-table-column field="created" label="Created" sortable>
          <span class="tag is-warning">
            {{ props.row.created }}
          </span>
        </b-table-column>

        <b-table-column field="actions" label="Actions">
          <b-button tag="router-link"
            :to="'/accounts/' + props.row.id + '/update'"
            type="is-info">
            Update
          </b-button>
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                icon="emoticon-sad"
                size="is-large">
              </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'AccountsOverview',
  data: function() {
    return {
      loading: false,
      connection_error: false,

      accounts: [],

      lastRequest: {},
      total: 0,
      limit: 20,
    }
  },
  created: function() {
    this.fetch({
      limit: this.limit,
    })
  },
  computed: {
    data: function() {
      if (this.accounts.length) {
        const moment = this.$moment

        return this.accounts.map(account => {
          return {
            id: account._id,
            name: account.name,
            email: account.email,
            status: account.status,
            updated: moment(account.updatedAt).format('D / MM / YY - h:m A'),
            created: moment(account.createdAt).format('D / MM / YY - h:m A'),
          }
        })
      }
      else {
        return []
      }
    },
  },
  methods: {
    reload: function() {
      this.fetch(this.lastRequest)
    },
    fetch: function(requestOptions={}) {
      const thisCompo = this

      this.loading = true
      this.connection_error = false
      this.lastRequest = requestOptions


      this.$api.accountsList(requestOptions).then((response) => {
        thisCompo.accounts = response.data.list

        thisCompo.limit = response.data.limit
        thisCompo.total = response.data.total

        thisCompo.loading = false
      }).catch( (err) => {
        if (err.response) {
          thisCompo.$buefy.toast.open({
            message: 'Bad request',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Connection error',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }

        thisCompo.loading = false
        thisCompo.connection_error = true
      })
    },
    pageChange: function(pageNum) {
      this.fetch({
        limit: this.limit,
        skip: this.limit * ( pageNum - 1 ),
      })
    },

    statusName: function(id) {
      switch(id) {
        case 1:
          return 'Active'

        case 2:
          return 'Blocked'

        case 3:
          return 'Unverified'

        default:
          return 'Unknown'
      }
    },
    statusClass: function(id) {
      switch(id) {
        case 1:
          return 'has-text-info'

        case 2:
          return 'has-text-danger'

        case 3:
          return 'has-text-warning'

        default:
          return 'has-text-grey-light'
      }
    }
  },
}
</script>
